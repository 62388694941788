<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    //- .col-lg-12.mb-2.title-box
      b-button-group.tab-group
        b-button.mr-2(variant="primary" :class="{off: tab !== 1}" @click="changeTab(1)") 選舉頁面設定
        b-button.mr-2(variant="primary" :class="{off: tab !== 2}" @click="changeTab(2)") 小知識設定
    .col-lg-12
      //- 搜尋bar
      //- b-card.col-lg-12.mb-1(no-body)
        b-collapse#customaccordion-1(v-model="visible", accordion="accordion", role="tabpanel")
          b-card-body
            form.form-horizontal(role="form")
              .row
                .col-lg-3
                  .form-group.mb-0
                    label.col-form-label(for="form-control") 頁面名稱
                    input.form-control(v-model="option", type="text" placeholder="請輸入頁面名稱")
                .col-lg-3.mt-1
                  .form-group.mb-0
                    label 選舉類型
                    multiselect(v-model="value", :options="options", placeholder="請選擇區域", select-label="", :multiple="true")
                .col-auto.mt-4
                  b-button.width-md(variant="primary") 查詢
      //- 表格
      .card
        .card-body
          //- .header-row.mb-3.row.text-right
            .col
              b-button.width-lg(variant="primary" to="/election-editor") ＋　新增選舉頁面
          //- Table
          .table-responsive.mb-0
            b-table(:items="elections", :fields="fields", responsive="sm", :per-page="perPage", :current-page="currentPage", :sort-desc.sync="sortDesc", :filter="filter", :filter-included-fields="filterOn", @filtered="onFiltered")

              //- index
              template(v-slot:cell(index)="row") {{ row.index + 1 }} 
              template(v-slot:cell(type)="row") 
                span(v-if="row.item.type == 1") 九合一選舉
                span(v-if="row.item.type == 2") 總統大選
                span(v-if="row.item.type == 3") 立委補選

              template(v-slot:cell(status)="row") 
                span(v-if="!row.item.status") 未啟用
                span(v-if="row.item.status") 已啟用
              //- 編輯
              template(v-slot:cell(edit)="row")
                //- b-button.table-btn.mr-2(to="/election-editor")
                  | 編輯
                b-button.table-btn.mr-2(:to="'/election-candidates/' + row.item.id ")
                  | 編輯候選人
                //- b-button.table-btn.mr-2(v-if="!row.item.status")
                  | 上架
                //- b-button.ml-2(variant="danger" size="sm")
                  i.fe-trash-2 
          .row.mb-md-2
            //- .col-6
              div(id="tickets-table_length", class="dataTables_length")
                label.d-inline-flex.align-items-center
                  | Show
                  b-form-select.ml-2.mr-2(v-model="perPage", size="sm", :options="pageOptions")
                  | entries
            .col-12.row.d-flex.justify-content-end  
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  // pagination
                  b-pagination(v-model="currentPage", :total-rows="rows", :per-page="perPage")
      //- 表格二
      //- .card(style="display: none")
        .card-body.col-lg-12
          .header-row
            .form-group.col-lg-9.pl-3.row.mb-3
              .form-group.col-lg-5.pl-0.mb-0
                multiselect(v-model="value", :options="options", select-label="",placeholder="建立時間(新-舊)",  :multiple="true")
              .pl-2.mt-2.mb-0
                b-form-checkbox#checkbox-1(v-model="status", name="checkbox", value="accepted", unchecked-value="not_accepted") 總統大選
              .pl-2.mt-2.mb-0
                b-form-checkbox#checkbox-1(v-model="status", name="checkbox", value="accepted", unchecked-value="not_accepted") 九合一選舉
            .col-lg-3.mt-0.mb-4.row.d-flex.justify-content-end  
              b-button.tipsbtn.width-lg(variant="primary") ＋　新增小知識
          //- Table
          .table-responsive.mb-0
            b-table(:items="tableData", :fields="fields2", responsive="sm", :per-page="perPage", :current-page="currentPage", :sort-desc.sync="sortDesc", :filter="filter", :filter-included-fields="filterOn", @filtered="onFiltered")

          .row.mb-md-2
            //- .col-6
            //-   div(id="tickets-table_length", class="dataTables_length")
            //-     label.d-inline-flex.align-items-center
            //-       | Show
            //-       b-form-select.ml-1.mr-1(v-model="perPage", size="sm", :options="pageOptions")
            //-       | entries
            .col-12.row.d-flex.justify-content-end  
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  // pagination
                  b-pagination(v-model="currentPage", :total-rows="rows", :per-page="perPage")
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

/**
 * Starter component
 */
export default {
  data () {
    return {
      rows: 0,
      perPage: 1,
      currentPage: 1,
      title: "設定列表",
      items: [
        {
          text: "選舉頁面專區",
          href: "/election",
        },
        {
          text: "設定列表",
          active: true,
        }
      ],
      search_data: {
        status: 0,
      },
      value: '',
      daterange: '',
      visible: true,
      options: [],
      elections: [],
      tableData: [
        {
          id: "4NQW9mWgXA",
          name: "2014九合一大選",
          type: 2,
          poll_date: "2014-11-29 00:00:00",
          created_at: "2022-08-20 23:35:11",
          creater: [],
          updated_at: "2022-09-28 15:34:44",
          editer: {
            id: "jK3NKqZ8n5",
            name: "中天快點TV"
          },
          status: 0,
          use_model: 1,
          current: false
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields: [
        {
          key: "index",
          label: "項次",
          class: "width-auto center"
        },
        {
          key: "name",
          label: "頁面名稱",
          class: "width-auto center"
        },
        {
          key: "type",
          label: "選舉類型",
          class: "width-auto center"
        },
        {
          key: "poll_date",
          label: "投票日期",
          class: "width-auto center"
        },
        {
          key: "created_at",
          label: "上線時間",
          class: "width-auto center"
        },
        {
          key: "status",
          label: "狀態",
          class: "width-auto center"
        },
        {
          key: "updated_at",
          label: "最後更新時間",
          class: "width-auto center"
        },
        {
          key: "edit",
          label: "操作",
          class: "width-auto center"
        },
      ],
      tab: 1
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows () {
      return this.tableData.length;
    },
  },
  mounted () {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  },
  created () {
    this.GetElectionList()
  },
  methods: {
    GetElectionList () {
      let vm = this
      this.$root.apis.getElectionList(function (_response) {
        let response = _response.body.data
        vm.elections = response.items
        vm.currentPage = response.page
        vm.rows = response.total
        vm.perPage = response.per_page
      })
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    changeTab (_status) {
      this.tab = _status
    }
  }
};
</script>

<style lang="sass" scoped>
.set-content
  .form-horizontal
    display: flex
    justify-content: space-between
    align-items: center
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  justify-content: space-between
  align-items: center
  .header-title
    margin: 0
    flex: 1
.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)
::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
</style>
